import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PageSplash from "../../components/PageSplash/PageSplash"
import { graphql, useStaticQuery } from "gatsby"

import { ValueProp, ValuePropHeader } from "../../components/ValueProp/ValueProp"
import IconRow from "../../components/IconRow/IconRow"
import IconRowItem from "../../components/IconRow/IconRowItem"

import LoginIcon from "../../images/icons/log-in.inline.svg"
import PieIcon from "../../images/icons/pie.inline.svg"
import ChevRightIcon from "../../images/icons/chev-right.inline.svg"
import MaximizeIcon from "../../images/icons/maximize.inline.svg"

import ScissorIcon from "../../images/icons/scissor.inline.svg"
import LocationIcon from "../../images/icons/location.inline.svg"
import Icon247 from "../../images/icons/24-7.inline.svg"
import FeatherIcon from "../../images/icons/feather.inline.svg"
import CoffeeIcon from "../../images/icons/coffee.inline.svg"

import AwardIcon from "../../images/icons/award.inline.svg"
import GridIcon from "../../images/icons/grid.inline.svg"
import LayersIcon from "../../images/icons/layers.inline.svg"
import ThumbsUpIcon from "../../images/icons/thumbs-up.inline.svg"
import TabletIcon from "../../images/icons/tablet.inline.svg"
import BarChartIcon from "../../images/icons/bar-chart-alt.inline.svg"
import TrendingUpIcon from "../../images/icons/trending-up.inline.svg"
import SettingsIcon from "../../images/icons/settings.inline.svg"
import SmileIcon from "../../images/icons/smile.inline.svg"
import UsersIcon from "../../images/icons/users.inline.svg"

import TrainingScreenshotDe from "../../images/pages/products/training/screenshot-en"
import ScreenshotStatisticImage from "../../images/pages/products/training/screenshot-statistic-en.svg"

import Chart from "../../images/pages/products/training/chart-en.inline.svg"
import Knowledge from "../../images/pages/products/training/knowledge-en.inline.svg"

import Style from "./training.module.css"
import PreHeading from "../../components/PreHeading"
import HighlightSection from "../../components/HighlightSection"
import { StepperItem } from "../../components/Stepper/Stepper"
import CustomerSlider from "../../components/CustomerSlider"

import { MediaAndText } from "../../components/ImageAndText/ImageAndText"
import { StartNowEn } from "../../components/StartNow"
import { SupportEn } from "../../components/Support"
import { PageType } from "../../index"
import ProductNav from "../../components/ProductNav/ProductNav"

const TrainingEn: PageType = ({ location }) => {
    const {
        splash,
    } = useStaticQuery(graphql`
        query TrainingEn {
            splash: file(relativePath: { eq: "pages/products/training/splash.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 680) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return <Layout location={location}>
        <ProductNav />
        <Seo
            title="Ensure Sustainable Knowledge"
        />
        <PageSplash
            preHeading="Digital Knowledge Management"
            heading="Ensure Sustainable Knowledge"
            cta="Explore"

            image={splash}
            imageFit={{
                objectPosition: "top right"
            }}
        />
        <span aria-hidden id="content" />
        <ValueProp>
            <ValuePropHeader
                preHeading="Enabled Knowledge"
                heading="Gaming is Learning – Gain and Sustain Game Enabled knowledge"
            >
                <p>
                    Learning with QUADIO is equal to measuring as well as sustaining your knowledge. Research on learning has proved that knowledge acquired by games has a prolonging and sustainable effect. Taking into account your pre-gained knowledge and individual pace of learning, QUADIO offers digital training modules, that transmit condensed expertise, contribute to success stories through instant feedbacks and make individual learning developments reviewed, measured and verified.
                </p>
            </ValuePropHeader>

            <IconRow>
                <IconRowItem icon={<LoginIcon />}>
                    Efficacious Onboarding
                </IconRowItem>
                <IconRowItem icon={<ChevRightIcon />}>
                    Efficient Knowledge Transfer
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Departmental Assessments
                </IconRowItem>
                <IconRowItem icon={<MaximizeIcon />}>
                    Scalable Models
                </IconRowItem>
            </IconRow>

            <TrainingScreenshotDe className="max-w-full m-auto" />
        </ValueProp>
        <section>
            <div aria-hidden hidden className="block mobile:hidden"><Chart className="w-full" /></div>
            <div className={`flex mobile:flex-wrap py-32 mobile:py-0 ${Style.blueGradient}`}>
                <div className="flex w-2/3 sm_desktop:w-3/5 mobile:w-full sm_desktop:flex-wrap">
                    <div className="w-1/2 sm_desktop:w-full px-16 pl-32 sm_desktop:pl-16 tablet:px-8">
                        <PreHeading>Training and Knowledge Acquisition</PreHeading>
                        <h2>Return On Learning Invest</h2>
                    </div>
                    <div className="w-1/2 sm_desktop:w-full px-16 tablet:px-8">
                        <p>
                            Recent studies on conventional training have proved that only 20 per cent of the content can be recalled immediately after a seminar or workshop. Additionally, the more detailed the learning becomes, the less likely it will reminisce and the individual learning progress will hardly be verifiable as well. QUADIO makes it possible by remarkably increasing the Return on Learning Invest for it is proven that playfulness and repetitive mode make knowledge get deeply rooted in the mind whilst the outcome is obvious: trainings which eventually pay off with an added value.
                        </p>
                    </div>
                </div>
                <div className="w-1/3 sm_desktop:w-2/5 mobile:w-full px-16 tablet:px-8">
                    <Knowledge className="m-auto w-full max-w-md max-h-full m-auto" />
                </div>
            </div>
        </section>
        <HighlightSection
            stepperItems={<>
                <StepperItem
                    heading="Digital and Paperless"
                    icon={<ScissorIcon />}
                >
                    Your core values, vision & mission and corporate culture – each individually adapted for employees to effortlessly identify themselves with the organization.
                </StepperItem>
                <StepperItem
                    heading="Simple and effortless administration"
                    icon={<LocationIcon />}
                />
                <StepperItem
                    heading="Retrievable from anywhere"
                    icon={<Icon247 />}
                />
                <StepperItem
                    heading="Process simplified"
                    icon={<CoffeeIcon />}
                />
                <StepperItem
                    heading="Support on content"
                    icon={<FeatherIcon />}
                />
            </>}
        >
            <div className="my-auto">
                <PreHeading>Digital Data</PreHeading>
                <h2>Digitalization of Knowledge Management</h2>
                <p>
                    Digital data – not bound to paper and file system any more, retrievable from anywhere at anytime, scalable, instinctive organizational wide activities (competitions, campaigns and incentives) as well as simple and effortless administration of employee profiles.
                </p>
            </div>
        </HighlightSection>
        <section className="section-x-margin">
            <MediaAndText
                cutOff
                noImageLimit
                contentSide={{
                    desktop: "right",
                    mobile: "bottom"
                }}
                image={<img alt="" src={ScreenshotStatisticImage} className="tablet:section-x-padding w-full pointer-events-none" />}
            >
                <PreHeading>Comprehensive and In-depth Assessments</PreHeading>
                <h2>Demonstrate Your Knowledge Obtained</h2>
                <p>
                    Ensure knowledge does exist at specific spheres and do not lack confidence in this conception. In the same vein, systematically establish a transparent platform for knowledge that is inexistent.<br />
                    Decisions having organizational relevancy should be based on the fundamental principle of actual time assessment and evaluation.
                </p>
            </MediaAndText>
        </section>
        <SupportEn>
            <li>Setting-up tailored organizational specific project and strategic assistance and consulting</li>
            <li>Trainings</li>
            <li>Corporate Design</li>
            <li>Technical support</li>
        </SupportEn>
        <section className="section-x-margin section-x-padding">
            <PreHeading>Overview</PreHeading>
            <h2>Features</h2>

            <IconRow className="mt-16 mobile:mt-12" fluid>
                <IconRowItem icon={<GridIcon />}>
                    Training Modules after Coaching Sessions
                </IconRowItem>
                <IconRowItem icon={<LayersIcon />}>
                    Insight into Training Materials
                </IconRowItem>
                <IconRowItem icon={<ThumbsUpIcon />}>
                    Evaluation of Training
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Data Analysis
                </IconRowItem>
                <IconRowItem icon={<TabletIcon />}>
                    Anywhere az Anytime
                </IconRowItem>
                <IconRowItem icon={<BarChartIcon />}>
                    Contests to increase Temporary Traffic
                </IconRowItem>
                <IconRowItem icon={<TrendingUpIcon />}>
                    Live Data and Assessment
                </IconRowItem>
                <IconRowItem icon={<UsersIcon />}>
                    User Management
                </IconRowItem>
                <IconRowItem icon={<SettingsIcon />}>
                    Training Management
                </IconRowItem>
                <IconRowItem icon={<FeatherIcon />}>
                    Content Management System
                </IconRowItem>
                <IconRowItem icon={<SmileIcon />}>
                    Gamification and Motivation
                </IconRowItem>
                <IconRowItem icon={<AwardIcon />}>
                    Programmed Zertifikate
                </IconRowItem>
            </IconRow>
        </section>
        <section className="section-x-margin">
            <CustomerSlider />
        </section>
        <StartNowEn />
    </Layout>
}

export default TrainingEn
